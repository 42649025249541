/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import React, {
  useCallback, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import actions from 'actions';
import {
  IAppState, IProjectCollection, IProjectCollectionDocument, IProjectItem, IProjectSavedQuery,
} from 'shared/interfaces';
import {
  FaCompress, FaExpand, FaExpandArrowsAlt, FaFolder,
} from 'react-icons/fa';

import './index.css';
import {
  addCollectionThunk, addProjectThunk, editCollectionThunk, editProjectThunk, editSavedQueryThunk,
} from 'reducers/projectsReducer/thunks';
import useProjects from 'hooks/useProjects';
import { IoMdFolderOpen } from 'react-icons/io';
import ProjectsList from './columns/projects-list';
import ProjectOptions from './columns/project-options';
import CollectionsList from './columns/collections-list';
import Collection from './columns/collection';
import Queries from './columns/queries';
import RoleGuardedComponent from '../role-guarded-component';

export default function Projects() {
  const dispatch = useDispatch();
  const navHistory = useHistory();

  const projects = useSelector((state: IAppState) => state.projects);

  const projectsNavRef = useRef<null | HTMLDivElement>(null);
  const projectsListRef = useRef<null | HTMLDivElement>(null);
  const projectOptionsRef = useRef<null | HTMLDivElement>(null);
  const collectionsListRef = useRef<null | HTMLDivElement>(null);
  const collectionRef = useRef<null | HTMLDivElement>(null);
  const queriesRef = useRef<null | HTMLDivElement>(null);

  const [openProjectsUI, setOpenProjectsUI] = useState(false);
  const [fullScreenProjectsUI, setFullScreenProjectsUI] = useState(false);

  const [initiateUseProjects, setInitiateUseProjects] = useState(projects.active);

  const { isLoading: isLoadingProjects } = useProjects(initiateUseProjects);

  const handleOpenProjects = useCallback((status: boolean, e: any) => {
    e.preventDefault();
    if (!initiateUseProjects && !projects.active) {
      setInitiateUseProjects(true);
    }
    if (status) setFullScreenProjectsUI(false);
    setOpenProjectsUI(status);
  }, [initiateUseProjects]);

  const handleFullScreenProjects = (status: boolean, e: any) => {
    e.preventDefault();
    if (status) setOpenProjectsUI(false);
    setFullScreenProjectsUI(status);
  };

  const scrollTo = (columnType : string) => {
    let targetNode;

    switch (columnType) {
      case 'project-list':
        if (projectsListRef.current) targetNode = projectsListRef;
        break;
      case 'project-actions':
        if (projectOptionsRef.current) targetNode = projectOptionsRef;
        break;
      case 'collections-list':
        if (collectionsListRef.current) targetNode = collectionsListRef;
        break;
      case 'collection':
        if (collectionRef.current) targetNode = collectionRef;
        break;
      case 'queries':
        if (queriesRef.current) targetNode = queriesRef;
        break;
      default:
    }

    if (targetNode && targetNode.current) {
      projectsNavRef.current?.scroll({
        left: targetNode.current.offsetLeft - 50, top: targetNode.current.offsetTop, behavior: 'smooth',
      });
    }
  };

  /** UI NAVIGATION OVER PROJECTS * */
  const selectProject = (project: IProjectItem) => {
    dispatch(actions.projectsSelectProject(project));
    scrollTo('project-actions');
  };

  const setViewCollections = (viewCollections: boolean) => {
    dispatch(actions.projectsViewCollections(viewCollections));
    scrollTo('collections-list');
  };

  const setViewQueries = (viewQueries: boolean) => {
    dispatch(actions.projectsViewQueries(viewQueries));
    scrollTo('queries-list');
  };

  const selectCollection = (project: IProjectCollection) => {
    dispatch(actions.projectsSelectCollection(project));
    scrollTo('collection');
  };

  const selectQuery = (query: IProjectSavedQuery) => {
    dispatch(actions.projectsSelectQuery(query));
  };

  const selectDocument = (document: IProjectCollectionDocument) => {
    dispatch(actions.projectsSelectDocument(document));
  };

  const viewDocument = () => {
    if (projects.UIselectedDocument) {
      // Check if the document is already opened
      const searchParams = navHistory.location.search ? new URLSearchParams(navHistory.location.search) : null;
      if (!searchParams || searchParams.get('path') !== projects.UIselectedDocument.path) {
        navHistory.push({
          pathname: 'pdfView',
          search: `?path=${projects.UIselectedDocument.path}`
            + `&storagePath=${projects.UIselectedDocument.storagePath}`
            + '&shouldReload=true',
        });
        dispatch(actions.setPdfPreview({ showPdf: true, pdfURL: projects.UIselectedDocument.storagePath, pdfFileName: projects.UIselectedDocument.name }));
      }
    }
  };

  /** ACTIONS ON PROJECTS * */
  const addProject = (name: string) => {
    if (name) {
      const newProject : IProjectItem = {
        ProjectID: `${projects.Username}-temp-${Date.now()}`,
        ProjectName: name,
        NoOfCollection: 0,
        Collection: [],
        NoOfSavedQueries: 0,
        SavedQueries: [],
        Shared: false,
      };

      dispatch(addProjectThunk(newProject));
    }
  };

  const editProject = (editProjectItem: IProjectItem) => {
    if (editProjectItem.ProjectID) {
      dispatch(editProjectThunk(editProjectItem));
    }
  };

  const addCollection = (name: string) => {
    if (name) {
      const newCollection : IProjectCollection = {
        ProjectId: projects.UIselectedProject?.ProjectID || '',
        CollectionName: name,
        Description: '',
        FileDataForCollection: [],
      };
      dispatch(addCollectionThunk(newCollection));
    }
  };

  const editCollection = (editCollectionItem: IProjectCollection) => {
    if (editCollectionItem.CollectionId) {
      if (projects.UIselectedProject) {
        dispatch(editCollectionThunk(editCollectionItem, projects.UIselectedProject));
      }
    }
  };

  const editQuery = (editQueryItem: IProjectSavedQuery) => {
    if (editQueryItem.SavedQueryId) {
      if (projects.UIselectedProject) {
        dispatch(editSavedQueryThunk(editQueryItem, projects.UIselectedProject));
      }
    }
  };

  return (
    <>
      <RoleGuardedComponent
        requiredRoles={['Projects.Basic', 'Projects.Advance']}
      >
        <div className={`projects ${openProjectsUI ? 'half-mode' : ''} ${fullScreenProjectsUI ? 'full-mode' : ''}`}>
          <div className="projects-header">
            <IoMdFolderOpen size={30} onClick={(event) => handleOpenProjects(!openProjectsUI, event)} />
            <span onClick={(event) => handleOpenProjects(!openProjectsUI, event)}>Projects</span>
            {
              fullScreenProjectsUI === false
              && <FaExpandArrowsAlt className="full-screen-button" onClick={(event) => handleFullScreenProjects(!fullScreenProjectsUI, event)} size={25} />
            }
            {
              fullScreenProjectsUI
              && <FaCompress className="full-screen-button" onClick={(event) => handleFullScreenProjects(!fullScreenProjectsUI, event)} size={25} />
            }
          </div>
          {isLoadingProjects && (
            <div className="projects-loading">Loading Projects...</div>
          )}
          {!isLoadingProjects && (
          <div className="projects-nav" ref={projectsNavRef}>
            <ProjectsList addProject={addProject} editProject={editProject} selectProject={selectProject} innerRef={projectsListRef} />
            <ProjectOptions innerRef={projectOptionsRef} editProject={editProject} setViewCollections={setViewCollections} setViewQueries={setViewQueries} />
            <CollectionsList innerRef={collectionsListRef} editCollection={editCollection} addCollection={addCollection} selectCollection={selectCollection} />
            <Collection viewDocument={viewDocument} selectDocument={selectDocument} editCollection={editCollection} innerRef={collectionRef} />
            <Queries selectQuery={selectQuery} editQuery={editQuery} innerRef={queriesRef} />
          </div>
          )}
        </div>
      </RoleGuardedComponent>
    </>
  );
}
